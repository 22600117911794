import React, { useState } from "react";
import { Container, Image, Nav, Navbar } from "react-bootstrap";
import { FaGithub, FaInfoCircle, FaMailBulk, FaShopify } from "react-icons/fa";

import { Link } from "react-router-dom";

export default function NavbarMenu() {
  const [expanded, setExpanded] = useState(false);

  return (
    <Navbar
      expanded={expanded}
      className="navbarMenu"
      collapseOnSelect={true}
      fixed="top"
      expand="md"
      bg="transparent"
      variant="dark"
    >
      <Container style={{float:"right"}}>


      

        <Navbar.Brand style={{ marginRight:"10px", marginTop:"0px", padding:"0px"}}>

        {!expanded && (
          <Navbar.Toggle
            style={{ backgroundColor: "black", marginRight:"10px", marginTop:"0px", padding:"10px"}}
            onClick={() => setExpanded(!expanded)}
            aria-controls="responsive-navbar-nav"
          />
        )}
        
          <Link to="/" onClick={() => setExpanded(false)}>
            <Image
              src={require("./assets/TRUSTLOVE_LOGO_LARGE.png")}
              width="70%"
              style={{ minWidth: "300px" }}
            />
          </Link>
        </Navbar.Brand>

        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link>
              <Link
                onClick={() => window.open("https://TrustLove.shop")}
                to={""}
              >
                <div className="menuText" >
                  <FaShopify size={32} />
                  {"  "}
                  <span className="menuTextContent">SHOP</span><span className="menuTextContentSM">The latest and greatest merchandise.</span>
                </div>
              </Link>
            </Nav.Link>
          </Nav>

          <Nav>
            <Nav.Link>
              <Link to="about" onClick={() => setExpanded(false)}>
                <div className="menuText">
                  <FaInfoCircle size={32} />
                  {"  "}
                  <span className="menuTextContent">ABOUT</span><span className="menuTextContentSM">All about TRUSTLOVE, the music, the history, the vision.</span>
                </div>
              </Link>
            </Nav.Link>
          </Nav>

          <Nav>
            <Nav.Link>
              <Link to="contact-us" onClick={() => setExpanded(false)}>
                <div className="menuText">
                  <FaMailBulk size={32} />
                  {"  "}
                  <span className="menuTextContent">CONTACT</span><span className="menuTextContentSM">Contact us with comments or questions.</span>
                </div>
              </Link>
            </Nav.Link>
          </Nav>
          <Nav className="me-auto">
            <Nav.Link>
              <Link
                onClick={() =>
                  window.open("https://github.com/spaceghost69/TrustLove")
                }
                to={""}
              >
                <div className="menuText">
                  
                  <FaGithub size={32} />
                  {" "}
                  <span className="menuTextContent">GITHUB</span><span className="menuTextContentSM">TRUSTLOVE is open source.</span>
                </div>
              </Link>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
