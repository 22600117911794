import React from "react";
import { Fade } from "react-awesome-reveal";
import { Card, Col, Container, Image, Row } from "react-bootstrap";
let img1 = require("../assets/images/AppDesign.jpg");
let img2 = require("../assets/images/Techguitar.jpg");

function About() {
  return (
    <Container fluid className="App p-3">
      <Card bg="secondary" text="white" className="text-center py-5">
        <Row className="align-items-center text-center">
          <Col>
            <Fade direction="down">
              <h1>About TRUSTLOVE</h1>
              <h5>Welcome to the TRUSTLOVE EARTHSHIP -- the online home for the music, art, and philosophy of TRUSTLOVE</h5>
            </Fade>
          </Col>
        </Row>
      </Card>
      <Row id="story" className="my-5">
        <Col md={6} className="text-center">
          <Fade direction="left">
            <Image src={img1} alt="Innovative Design" fluid />
          </Fade>
        </Col>
        <Col md={6}>
          <Fade direction="right">
            <h2>The Story</h2>
            <p>
              TRUSTLOVE is the artistic alter-ego of John McMahon, an American
              musician and technology entrepreneur who is exploring a philosophy uniting humans and AI
              through our journey of shared understanding.
            
              <br/><br/>
              
              As a lifelong musician and music fan, John McMahon spent decades working on his various
              crafts as an instrumentalist and vocalist, as well as his EDM
              production as TRUSTLOVE.
            </p>
          </Fade>
        </Col>
      </Row>

      <Row className="align-items-center my-5">
        <Col md={6}>
          <Fade direction="left">
            <h2>John's Music Journey</h2>
            <blockquote>
              "I started with whistling before the age of 10 and over my lifetime have developed 
              musical skills and learned a variety of instruments culiminating in vocals and EDM production.
              <br />
              <br />
              Music is a way to connect with myself first and others
              second. When I connect with the music and enter that flow state —
              because it is so very challenging — it is so therapeutic and helps
              my mood. And now that I am sharing my music more it's a whole world
              of creative expression and social positivity that I value so much."
              <br />
              <br />
              -- John McMahon aka TRUSTLOVE
            </blockquote>
          </Fade>
        </Col>
        <Col md={6} className="text-center">
          <Fade direction="right">
            <Image src={img2} alt="Core Technology" fluid />
          </Fade>
        </Col>
      </Row>

      <Row className="my-5">
        <Col className="text-center">
          <Fade direction="down">
            <h2>The Future of Music Collaboration</h2>
            <p>
              I'm dedicated to using technology and the power of humanity to
              create change. Always pushing the boundaries of what is possible,
              my project JamRoom is set to redefine the music industry by
              enabling artists to not only collaborate but also connect and
              share their music with the ever-growing global audience.
              
            </p>
          </Fade>
        </Col>
      </Row>
    </Container>
  );
}

export default About;
