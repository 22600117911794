import React from "react";
import { Card, Carousel, Image } from "react-bootstrap";

interface CarouselCardsProps {
    carouselData: any;
  }
  
  const CarouselCards: React.FC<CarouselCardsProps> = ({ carouselData }) => {
    
    
    return (
        <Card bg="secondary" text="white" className="text-center py-5">
        <Carousel className="carousel" interval={10000}>
          {carouselData.map((carouselItem, key) => (
            <Carousel.Item key={key}>
              <div
                className="d-flex justify-content-center carouselCard"
              >
                <div>
                      <Card
                        bg="secondary"
                        text="white"
                        className="text-center py-5 carouselCard"
                      >
                        <Card.Header>
                        <Image className="coolImage" src={carouselItem.image} />
                        </Card.Header>
                        <p
                          className="coolText"
                        >
                          <h4>{carouselItem.title}</h4>
                          <h5>{carouselItem.description}</h5>
                          
                          {carouselItem.longDescription}
                        </p>
                       
                       
                      </Card>
                    
                </div>
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </Card>
    );
  }
  
  export default CarouselCards;



